import React from 'react'
import Layout from '../layouts'
import {navigate} from 'gatsby'
import {PageNotFoundSvg} from '../components/assets/images/404_page'
const NotFoundPage = () => (
  <Layout page={`formal`}>
    <div className="page-not-found">
     <PageNotFoundSvg>
     <g id="Group_213" data-name="Group 213" transform="translate(3180.716 6833)">
      <g id="Group_208" data-name="Group 208" transform="translate(155 44)">
        <g id="group-32" transform="translate(707 -46)">
          <text id="Stop_wasting_a_8" data-name="Stop wasting a " className="cls-n13" transform="translate(317.284 554)"><tspan x="-211.596" y="0">404</tspan></text>
        </g>
        <text id="Stop_wasting_a_9" data-name="Stop wasting a " className="cls-n14" transform="translate(950 569)"><tspan x="-150" y="0">We're sorry someting</tspan><tspan x="-59.55" y="50">goes wrong</tspan></text>
      </g>
      <g id="Group_211" data-name="Group 211" style={{cursor:'pointer'}} transform="translate(0 100)" onClick={()=>navigate('/')}>
        <text id="Stop_wasting_a_10" data-name="Stop wasting a " className="cls-n15" transform="translate(1086 671)"><tspan x="-105.24" y="0">Return to homepage</tspan></text>
        <line id="Line_11" data-name="Line 11" className="cls-n8" x2="212" transform="translate(1100 700)"/>
      </g>
    </g>
       </PageNotFoundSvg>
    </div>
  </Layout>
)

export default NotFoundPage
