import React from 'react'
import './style.scss'
const PageNotFoundSvg = ({children}) =>{
  return(
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="3165 6828 1920 1080">
  <defs>
 
    <clipPath id="clip-path">
      <rect id="Rectangle_22" data-name="Rectangle 22" className="cls-n1" width="460" height="853" transform="translate(500 227)"/>
    </clipPath>
    <clipPath id="clip-JD_About_us_1">
      <rect x="3165" y="6828" width="1920" height="1080"/>
    </clipPath>
  </defs>
  <g id="JD_About_us_1" data-name="JD_About us – 1" className="cls-n2">
   {children}
    <g id="Mask_Group_4" data-name="Mask Group 4" className="cls-n16" transform="translate(3165 6828)">
      <g id="Group_210" data-name="Group 210" transform="translate(-65.284 -46)">
        <g id="group-45" transform="translate(565.284 273.053)">
          <g id="group-28" transform="translate(0 0)">
            <g id="group-26" transform="translate(0 0)">
              <g id="group-24" transform="translate(0 49.927)">
                <path id="path-2" data-name="path" className="cls-n17" d="M126.619,980.792c-14.517-90.771-92.282-166.647-169.183-198-5.936-8.311.864-17.053,6.044-21.91q2.266-1.781,4.479-3.724h0a150.676,150.676,0,0,0,49-105.126,47.911,47.911,0,0,0,10.793-14.139c8.527-16.46,6.8-34.268-3.777-39.719a16.521,16.521,0,0,0-12.79-.593C-4.626,540.484-49.958,499.2-103.438,499.2s-99.351,41.715-114.893,99.243a17.436,17.436,0,0,0-15.165-.27c-10.793,5.4-12.3,23.26-3.778,39.719a46.249,46.249,0,0,0,13.654,16.19c3.184,44.36,23.475,82.892,52.994,106.421h0l1.133.863c4.587,3.671,18.888,17.054-.918,29.358l-5.4,3.023h0c-74.257,40.1-147.057,112.087-149.108,206.636-1.619,71.936,13.384,208.848,50.188,267.294h0l1.349,2.211a303.487,303.487,0,0,1,10.038,30.384h0a64.318,64.318,0,0,0,13.221,23.906c19.859,22.881,51.16,28.278,69.886,11.98s17.754-47.976-2.105-70.857a58.712,58.712,0,0,0-45.494-21.586c-25.741-33.405-36.912-145.708-35.887-192.281,1.188-53.48,6.638-118.725,61.467-153.048-24.878,82.622-24.015,169.669-24.878,233.834,0,.539,2.59,51.43,8.419,89.583a35.833,35.833,0,0,0,4.264,2.1l8.634-38.962H5.682a107.848,107.848,0,0,1-9.174,7.933l22.45,47.165c1.835-.863,14.625-41.175,32.38-55.152l-3.022-44.414c-4.533-53.966-15.056-146.841-37.776-226.656l-.27-.972L-.147,884.571c51.753,30.382,64.759,104.8,73.016,154.936,6.854,42.794,10.793,139.069-5.4,181.433a58.607,58.607,0,0,0-45.331,22.019c-19.536,23.2-19.967,54.937-1.025,70.911s50.188,10.146,69.67-13.06a63.845,63.845,0,0,0,13.384-26.173h0a55.739,55.739,0,0,0,1.349-8.58,164.036,164.036,0,0,1,7.825-29.466v-.324C141.73,1176.526,137.952,1051.595,126.619,980.792Z" transform="translate(325.032 -499.2)"/>
                <path id="path-3" data-name="path" className="cls-n18" d="M-301.48,733.74" transform="translate(428.58 531.977)"/>
                <path id="path-4" data-name="path" className="cls-n19" d="M-14.211,679.84l-.27-.972L-24.95,650.536c21.586,12.574,36.157,32.7,46.788,55.477l56.556-28.008A300.212,300.212,0,0,0-57.383,552.48c-19.1,21.208-43.173,33.783-68.807,33.783-24.986,0-48.029-11.765-66.81-31.623-.7.485-1.349,1.025-2.158,1.51l-5.4,3.022h0c-45.439,24.5-90.285,60.981-118.725,107.176l48.893,47.922a114.733,114.733,0,0,1,43.5-50.027c-24.932,82.784-24.069,170.047-24.932,234.375,0,.378,1.241,24.933,3.939,52.671H27.343l-3.022-44.306C19.086,853.232,8.509,759.87-14.211,679.84Z" transform="translate(350.32 -264.95)"/>
                <path id="path-5" data-name="path" className="cls-n20" d="M83.875,677.858h69.616l12.628-440.2h-1.025l28.224,442.683,62.169,5.019,38.855-557.145L18.63,126.92Z" transform="translate(82.026 557.962)"/>
                <path id="path-6" data-name="path" className="cls-n20" d="M-202.969,727.74h-67.458l-4.8,82.082s171.018,22.4,151.752-4.965S-202.969,727.74-202.969,727.74Z" transform="translate(543.99 505.597)"/>
                <path id="path-7" data-name="path" className="cls-n20" d="M-258.88,732.81s167.618,65.028,20.075,63.3" transform="translate(615.875 527.889)"/>
                <rect id="rectangle" className="cls-n18" width="10.146" height="46.627" rx="0.94" transform="translate(350.507 1236.91) rotate(60)"/>
                <rect id="rectangle-2" data-name="rectangle" className="cls-n18" width="10.146" height="46.627" rx="0.94" transform="translate(366.808 1247.786) rotate(60)"/>
                <rect id="rectangle-3" data-name="rectangle" className="cls-n18" width="10.146" height="46.627" rx="0.94" transform="translate(382.156 1258.442) rotate(60)"/>
                <path id="path-8" data-name="path" className="cls-n20" d="M-226.141,728.2s-62.169,49.7-82.082,77.118,156.5,4.965,156.5,4.965l-4.965-82.082Z" transform="translate(392.043 507.62)"/>
                <path id="path-9" data-name="path" className="cls-n20" d="M-254.529,803.606c-147.489,1.726,20.075-63.3,20.075-63.3l9.984-8.634c4.425,18.833,38.261,24.338,14.894,24.122" transform="translate(368.03 522.876)"/>
                <rect id="rectangle-4" data-name="rectangle" className="cls-n18" width="10.146" height="46.627" rx="0.94" transform="translate(116.932 1269.748) rotate(-60)"/>
                <rect id="rectangle-5" data-name="rectangle" className="cls-n18" width="10.146" height="46.627" rx="0.94" transform="translate(133.233 1258.818) rotate(-60)"/>
                <rect id="rectangle-6" data-name="rectangle" className="cls-n18" width="10.146" height="46.627" rx="0.94" transform="translate(148.554 1248.209) rotate(-60)"/>
              </g>
              <path id="path-17" className="cls-n20" d="M-299.952,641.348s-18.068-51.244,6.275-89.784c0,0,13.523,22.592,32.457,0s36.514-99.671,164.988-41.2c0,0-25.695,29.238-10.818,41.2,0,0,62.1,35.616,37.163,88.933,0,0-47.116-100.308-107.053-75.324S-212.914,530.461-299.952,641.348Z" transform="translate(406.808 -488.902)"/>
            </g>
          </g>
        </g>
        <g id="Group_209" data-name="Group 209" transform="translate(725.179 443.718)">
          <g id="Ellipse_7" data-name="Ellipse 7" className="cls-n17" transform="translate(0 0)">
            <ellipse className="cls-n22" cx="15.139" cy="15.139" rx="15.139" ry="15.139"/>
            <ellipse className="cls-n23" cx="15.139" cy="15.139" rx="14.139" ry="14.139"/>
          </g>
          <g id="Ellipse_8" data-name="Ellipse 8" className="cls-n17" transform="translate(94.621 0)">
            <ellipse className="cls-n22" cx="15.139" cy="15.139" rx="15.139" ry="15.139"/>
            <ellipse className="cls-n23" cx="15.139" cy="15.139" rx="14.139" ry="14.139"/>
          </g>
          <path id="Path_112" data-name="Path 112" className="cls-n21" d="M0,0S48.427,56.82,91.438,0" transform="translate(110.363 107.977) rotate(180)"/>
        </g>
      </g>
    </g>
  </g>
</svg>
  )
}

export {PageNotFoundSvg}